import React, { useState } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import classNames from 'classnames'
import * as actionCreators from '../../../../../../../store/actions'
import * as renaultProjectModeActionCreators from '../../../../../state/actions'
import styles from '../../../../../styles/EditProject.module.scss'
import BlobUrlImage from '../../../Common/BlobUrlImage'
import { ScorePanel } from '../../../../../../../components/commons/score_panel'
import DotsIcon from '../../../../../../../assets/svg/dots.svg'
import { selectBrandbyProduct } from '../../../../../../../store/selectors/scopes'
import { selectProductById } from '../../../../../state/products/selectors'
import ProjectProductMenu from './ProjectProductMenu'
import { LoadingBar } from '../../../../../../../components/loading_bar'

function ProjectProductItem({ product, environment, texts, brand }) {
  const [menuAnchorEl, setMenuAnchorEl] = useState(null)

  const isMenuOpen = Boolean(menuAnchorEl)

  const { lang } = environment
  const brandName = product && _.find(product.props, { slug: 'brand' })?.value?.body[lang]
  const modelName = product && _.find(product.props, { slug: 'model' })?.value?.body[lang]
  const commercialName = product && _.find(product.props, { slug: 'commercial-name-grade' })?.value?.body[lang]
  const productName = product && `${brandName} ${modelName}`
  const productType = product?.is_configuration ? texts.type_configuration : texts.type_benchmark
  const logoUrl = brand && _.first(brand?.media)?.url
  const coverUrl = product && product.media && _.find(product.media, { collection: 'cover_pictures' })?.url
  const coverFile = product && product.media && _.find(product.media, { collection: 'cover_pictures' })?.file

  return (
    <>
      {product && (
        <>
          <div className={styles.ProjectProductItem}>
            <div className={styles.ProjectProductItem_cover}>
              <BlobUrlImage url={coverUrl ?? coverFile} />
            </div>

            <div className={styles.ProjectProductItem_logo}>
              <BlobUrlImage url={logoUrl} />
            </div>

            <div className={styles.ProjectProductItem_title}>
              <div
                className={classNames(
                  {
                    [styles.ProjectProductItem_type___benchmark]: !product.is_configuration,
                  },
                  styles.ProjectProductItem_type
                )}
              >
                {productType}
              </div>
              <div className={styles.ProjectProductItem_name}>{productName}</div>
              <div className={styles.ProjectProductItem_subtitle}>{commercialName}</div>
            </div>
            <div className={styles.ProjectProductItem_scorePanel}>
              <ScorePanel
                scorePanel={product.score_panel}
                size="mashed"
                maxProductScore={environment.config.max_product_score}
              />
            </div>
            <div className={styles.ProjectProductItem_actions}>
              <button
                type="button"
                className={styles.ProjectProductItem_actions_button}
                onClick={e => setMenuAnchorEl(e.currentTarget)}
              >
                <DotsIcon width="16px" className="theme_svg" />
              </button>
            </div>
          </div>
          {isMenuOpen && (
            <ProjectProductMenu anchorEl={menuAnchorEl} product={product} onClose={() => setMenuAnchorEl(null)} />
          )}
        </>
      )}
      {!product && (
        <div className={styles.ProjectItemsList_list_loading}>
          <LoadingBar />
        </div>
      )}
    </>
  )
}

const mapStateToProps = (state, ownProps) => {
  const product = selectProductById(state, ownProps.productId)

  return {
    texts: state.texts.values,
    environment: state.environment,
    product,
    brand: selectBrandbyProduct(state, product),
  }
}

export default connect(mapStateToProps, {
  ...actionCreators,
  ...renaultProjectModeActionCreators,
})(ProjectProductItem)
