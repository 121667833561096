import React, { useState } from 'react'
import { connect } from 'react-redux'
import { navigate } from 'gatsby'
import _ from 'lodash'
import * as actionCreators from '../../../../../../../store/actions'
import * as renaultProjectModeActionCreators from '../../../../../state/actions'
import ContextualMenu from '../../../Common/ContextualMenu'
import DuplicateIcon from '../../../../../../../assets/svg/duplicate.svg'
import XIcon from '../../../../../../../assets/svg/x.svg'
import { POPOVER_POSITION_BOTTOM_RIGHT } from '../../../Common/Popover'
import styles from '../../../../../styles/EditProject.module.scss'
import EditIcon from '../../../../../../../assets/svg/edit-scoring-icon.svg'
import Dialog from '../../../Common/Dialog'
import DuplicateConfigurationModal from '../../DuplicateConfigurationModal'
import { selectProjectConfCategoriesByProductId } from '../../../../../state/projects/selectors'

function ProjectProductMenu({
  product,
  projectId,
  anchorEl,
  onClose,
  texts,
  removeProductFromAllConfCategories,
  deleteProduct,
  environment,
  configurationCategories,
}) {
  const [openDuplicateModal, setOpenDuplicateModal] = useState(false)

  const [isLoading, setIsLoading] = useState(false)
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
  const [confirmDelete, setConfirmDelete] = useState(false)

  const { lang } = environment

  const productUrl = `/scoring?id=${product.id}`
  const brandName = _.find(product.props, { slug: 'brand' }).value.body[lang]
  const modelName = _.find(product.props, { slug: 'model' }).value.body[lang]
  const productName = `${brandName} ${modelName}`
  const productIsConfiguration = product.is_configuration

  const handleEdit = async () => {
    setIsLoading(true)
    navigate(productUrl)
    setIsLoading(false)
  }

  const handleClickMenuRemove = async () => {
    setOpenDeleteDialog(true)
    setConfirmDelete(false)
  }

  const handleClickDialogRemove = async () => {
    setConfirmDelete(true)
  }

  const handleRemoveBenchmarkConfirm = async () => {
    // setIsLoading(true)
    await removeProductFromAllConfCategories(product.id, projectId)
  }

  const handleRemoveConfigurationConfirm = async () => {
    // setIsLoading(true)
    await removeProductFromAllConfCategories(product.id, projectId)
    await deleteProduct(product.id)
  }

  const handleConfirmDelete = async isConfiguartion => {
    if (isConfiguartion) await handleRemoveConfigurationConfirm()
    else await handleRemoveBenchmarkConfirm()
  }

  const handleDuplicate = async () => {
    setOpenDuplicateModal(true)
  }

  const handleClose = () => {
    if (onClose && !openDeleteDialog && !openDuplicateModal) onClose()
  }

  const relatedConfigurationCategories = _.map(
    configurationCategories,
    confCategory => confCategory.name ?? texts.new_configuration_category_title
  ).join(', ')

  const dialogDeleteText = `${productIsConfiguration ? texts.are_you_sure_delete : texts.are_you_sure_remove}
  ${productName} ?  \n
  ${texts.the_products_is_used_in}:
  ${relatedConfigurationCategories} \n
  ${productIsConfiguration ? texts.if_you_delete : texts.if_you_remove} ${productName}, ${
    texts.it_will_be_removed_from_all_these_boards
  }.`

  const dialogDeleteConfirmText = `${
    productIsConfiguration ? texts.are_you_really_sure_delete : texts.are_you_really_sure_remove
  }  ${productName} ?`

  return (
    <>
      <ContextualMenu
        className={styles.ProjectProduct_menu}
        anchorEl={anchorEl}
        onClickAway={handleClose}
        fixed={false}
        direction="left"
        position={POPOVER_POSITION_BOTTOM_RIGHT}
        items={[
          {
            label: texts.edit,
            disabled: isLoading,
            icon: <EditIcon />,
            onClick: handleEdit,
          },
          {
            label: texts.duplicate_to_configuration,
            disabled: isLoading,
            icon: <DuplicateIcon />,
            onClick: handleDuplicate,
          },
          {
            label: productIsConfiguration ? texts.delete_configuration : texts.remove_from_project,
            disabled: isLoading,
            color: 'primary',
            icon: <XIcon />,
            onClick: handleClickMenuRemove,
          },
        ]}
      />

      <Dialog
        open={openDeleteDialog}
        setOpen={setOpenDeleteDialog}
        onConfirm={confirmDelete ? () => handleConfirmDelete(productIsConfiguration) : handleClickDialogRemove}
        title={`${productIsConfiguration ? texts.delete : texts.remove}  ${productName}`}
        text={confirmDelete ? dialogDeleteConfirmText : dialogDeleteText}
        confirmButtonText={productIsConfiguration ? texts.delete : texts.remove}
        textColor={confirmDelete && 'red'}
        closeOnConfirm={false}
        width={480}
      />
      {openDuplicateModal && (
        <DuplicateConfigurationModal
          onClose={() => {
            setOpenDuplicateModal(false)
            onClose()
          }}
          product={product}
          productName={productName}
        />
      )}
    </>
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    texts: state.texts.values,
    projectId: state.renaultProjectMode.editProject.id,
    environment: state.environment,
    configurationCategories: selectProjectConfCategoriesByProductId(
      state,
      state.renaultProjectMode.editProject.id,
      ownProps.product.id
    ),
  }
}

export default connect(mapStateToProps, {
  ...actionCreators,
  ...renaultProjectModeActionCreators,
})(ProjectProductMenu)
